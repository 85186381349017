/***************************************/
/*              IMPORTS                */
/***************************************/

/**** BOOTSTRAP ****/
@import "stylesheets/bootstrap.min.css";

/**** CSS RESET ****/
@import "antd/dist/reset.css";

// Remove Bootstrap's big ugly border around clicked buttons
button:focus,
button:active {
  outline: none !important;
}

/*** Fix icons not vertically aligned ***/
svg {
  vertical-align: unset;
}

/**** OTHER FILES ****/

@import "media-queries";
@import "animations";
@import "ant";
@import "layout";
@import "agenda";

/**** CUSTOM NOE COLORS AND FONTS ****/

@import "./noe.less";

/***************************************/
/*           SIDEBAR MENU              */
/***************************************/

// Add transitions necessary for menu items to work smoothly
.ant-menu-item {
  transition: box-shadow 0.5s, border-color 0.5s, background 0.5s,
    padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

// Background of the sidebar
.ant-menu-root {
  background: var(--noe-bg) !important;
}

// Selected elements : make a NOÉ gradient, made with background-color
// and inset bow-shadow (yeah that's the trick)
.ant-menu-item.ant-menu-item-selected:not(.ant-menu-item-danger) {
  background: var(--noe-accent-1-85) !important;
  box-shadow: -210px 0 100px -100px var(--noe-accent-2-85) inset !important;
}
// Adapt box shadow when the sidebar is collapsed
.ant-layout-sider-collapsed .ant-menu-item.ant-menu-item-selected:not(.ant-menu-item-danger) {
  box-shadow: -100px 0 70px -70px var(--noe-accent-2-85) inset !important;
}

// Create the appropriate container for the sidebar
.sidebar-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  height: 100%;
}

.sidebar-container > *:not([class="sidebar-footer"]) {
  flex-grow: 1;
}

/***************************************/
/*           OTHER TWEAKS              */
/***************************************/

/*** BUTTONS ***/

// TODO Colors legacy
@warning-color: #faad14;
@success-color: #52c41a;

.button-variant-primary(@color) {
  background-color: @color;
  box-shadow: 0 2px 0 rgba(lighten(@color, 20%), 10%);
  &:not(:disabled) {
    &:hover {
      background-color: lighten(@color, 10%);
    }
    &:focus,
    &:active {
      background-color: darken(@color, 10%);
    }
  }
}
.success-button {
  .button-variant-primary(@success-color);
}

.warning-button {
  .button-variant-primary(@warning-color);
}

/*** LINKS ***/

// Make links look like .ant. (Later, put that stuff directly in a design system)
a:not(.no-noe-link-color):not(.ant-btn) {
  color: var(--noe-primary);
  transition: all 0.2s;
  &:hover {
    color: color-mix(in srgb, var(--noe-primary) 70%, white);
  }
}

/*** STICKY STUFF µ**/

.sticky-element {
  position: sticky;
  top: 0;
  z-index: 50;
  background: var(--colorBgLayout);
  transition: border-bottom 0.1s ease-in-out;
  border-bottom: 2px solid var(--colorBgLayout);
  border-top: 2px solid var(--colorBgLayout);
}

.sticking-shadow-top {
  border-bottom: 2px solid #d0d0d0;
}

.sticking-shadow-bottom {
  border-top: 2px solid #d0d0d0;
}

/**** MOBILE NAVBAR ****/

.mobile-navbar-container {
  padding: 0 30pt;
  line-height: unset !important;
  height: 50px !important;
  z-index: 101;
  @media @md-and-more {
    display: none;
  }
}

.navbar-menu-button {
  position: fixed;
  top: 5px;
  left: 5px;
  z-index: 102;
  @media @md-and-more {
    display: none;
  }
}

.ant-layout-sider {
  @media @below-md {
    display: none;
  }
}
.mobile-sidebar-drawer {
  @media @md-and-more {
    display: none;
  }
}

/**** MISCELLANEOUS ****/

/**** HEADWAY NOTIF BADGE ****/
.HW_badge_cont {
  position: relative;
  right: -16px;
  bottom: -10px;
}
.HW_frame_cont {
  border-radius: 12px !important;
}

/*** When printing an image of NOÉ, tell to print everything, including background graphic, etc. ***/
* {
  -webkit-print-color-adjust: exact;
}
