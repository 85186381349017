@import "media-queries";

/********* HEADERS ********/

.header-space-between {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.list-element-header {
  padding-bottom: 10pt;
  padding-top: 10pt;
}

.list-page-header {
  padding: 17pt 15pt 10pt 15pt;
  @media @md-and-more {
    padding: 25pt;
  }
}

.edit-page-header {
  padding-bottom: 10pt;
  @media @md-and-more {
    padding-bottom: 20pt;
  }
}

.tabs-page-header {
  padding-bottom: 5pt;
  @media @md-and-more {
    padding-bottom: 10pt;
  }
}

.auth-page-container > * {
  margin-bottom: 18px;
}

/********* MARGINS ********/

.with-margins {
  padding-left: 15pt;
  padding-right: 15pt;
  @media @md-and-more {
    padding-left: 25pt;
    padding-right: 25pt;
  }
  &.ant-tabs-nav .ant-tabs-tab:nth-last-child(2) {
    margin-right: 30pt;
    @media @md-and-more {
      margin-right: 50pt;
    }
  }
}

.full-width-content {
  margin-right: -15pt;
  margin-left: -15pt;
  @media @md-and-more {
    margin-right: -25pt;
    margin-left: -25pt;
  }
}

/******** REVEAL ON HOVER ********/

.reveal-on-hover-container {
  & .reveal-on-hover-item {
    opacity: 0;
  }
  &:hover .reveal-on-hover-item {
    opacity: 1;
  }
}

/********* GENERIC LAYOUT ********/

.page-content {
  margin: 0 15pt;
  height: 100%;
  @media @md-and-more {
    margin: 0 25pt;
  }
}

.modal-page-content {
  margin: 0 8pt;
  @media @md-and-more {
    margin: 0 15pt;
  }
}

.page-container {
  padding: 17pt 0;
  @media @md-and-more {
    padding: 25pt 0;
  }

  &.no-bottom-padding {
    padding: 17pt 0 0 0;
    @media @md-and-more {
      padding: 25pt 0 0 0;
    }
  }
}

/********* GENERIC CONTAINERS ********/

.flex-container {
  display: flex;
  align-items: stretch;
}

.containerH {
  .flex-container;
  flex-direction: row;
  overflow: hidden;
}

.containerV {
  .flex-container;
  flex-direction: column;

  // nested containers
  & > & {
    flex-grow: 1;
    flex-shrink: 1;
  }
  & > .containerH {
    overflow: visible;
  }
}

.buttons-container {
  flex-wrap: wrap;
  justify-content: stretch;
  align-items: baseline;
  margin: -4pt;

  // Children of elements who are container + buttons-container
  .containerH& > *,
  .containerV& > * {
    margin: 4pt;
    &:not(.no-flex-grow) {
      flex-grow: 1;
    }
  }
}

.flex-space {
  justify-content: stretch;
  align-items: baseline;
  gap: 8px;

  // Children of elements who are container + buttons-container
  .containerH& > *,
  .containerV& > * {
    &:not(.no-flex-grow) {
      flex-grow: 1;
    }
  }
}

/*** Grids ***/

.container-grid {
  display: grid;
  grid-row-gap: 8pt;
  padding-bottom: 4pt;
  padding-top: 4pt;

  // nested container grids should not have padding
  & &,
  & & & {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

  &.two-per-row,
  &.three-per-row,
  &.two-thirds-one-third {
    grid-template-columns: repeat(1, 1fr);
  }

  @media @lg-and-more {
    // TODO Make sure it's at the right place
    grid-column-gap: 16pt;

    &.two-per-row,
    &.three-per-row {
      grid-template-columns: repeat(2, 1fr);
    }

    &.two-thirds-one-third {
      grid-template-columns: auto calc(33% - 8pt);
    }

    @media @below-xl {
      // TODO check if it is max xl or something else
      /* expand the last child if it is alone on its own line */
      &.two-per-row > *:last-child:nth-child(2n + 1),
      &.three-per-row > *:last-child:nth-child(2n + 1),
      &.two-thirds-one-third > *:last-child:nth-child(2n + 1) {
        grid-column: 1 / 3;
      }
    }

    @media @xl-and-more {
      grid-column-gap: 32pt;

      &.three-per-row {
        grid-template-columns: repeat(3, 1fr);
      }

      &.two-thirds-one-third {
        grid-template-columns: auto calc(33% - 16pt);
      }

      /* expand the last child if it is alone on its own line */
      &.two-per-row > *:last-child:nth-child(2n + 1) {
        grid-column: 1 / 3;
      }

      &.three-per-row > *:last-child:nth-child(3n + 1) {
        grid-column: 1 / 4;
      }
    }
  }
}

/************** SCROLLBAR **************/

::-webkit-scrollbar {
  width: 14px;
}

::-webkit-scrollbar-track {
  background-color: rgba(200, 200, 200, 0.2);
  border: 1px solid rgba(200, 200, 200, 0.27);
}

::-webkit-scrollbar-thumb {
  border-radius: 7px;
  border: 3px solid transparent;
  background-clip: content-box;
  background-color: rgba(130, 130, 130, 0.6);
}

/*** Printing ***/
.scrollable {
  overflow-y: auto !important;
  overflow-x: auto !important;

  @media print {
    overflow-y: visible !important;
    overflow-x: visible !important;
  }
}
