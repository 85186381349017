@import "noe";

.ql-snow {
  // no border for the box container
  &.ql-container {
    border: none;
    /* remove font size et to 13px instead of 14px */
    font-size: unset;
  }

  // TOOLBAR
  &.ql-toolbar {
    visibility: hidden;
    position: sticky;
    top: 4px;
    z-index: 1;
    border-color: var(--colorBorder);
    background: var(--colorBgElevated);
    border-radius: 11px;
    opacity: 0;
    max-height: 0;
    padding: 0;
    margin: 0;
    // DISAPPEARING TRANSITION
    // When disapearing progressively, everything is delayed during 2.8 seconds, then transitions for 1.2 second.
    // Opacity starts a bit ahead of time.
    // Visibility comes after everything is finished to make the component hidden and prevent misclicks when the toolbar appears again
    transition: all 1.2s ease-in-out 2.8s, opacity 2s ease-in-out 2s, visibility 0s ease-in-out 4s;

    & .ql-fill {
      fill: var(--text);
    }
    & .ql-picker-label {
      color: var(--text);
    }
    & .ql-stroke {
      stroke: var(--text);
    }

    /* show full opacity for the toolbar when in focus */
    .quill:focus-within & {
      visibility: visible;
      max-height: 200px;
      padding: 4px 6px;
      margin: -7px -7px 6px -7px;
      opacity: 1;
      // APPEARING TRANSITION
      // When appearing, visibility is toggled at the very begginning with delay.
      // Then everything appears in a half second
      transition: all 0.3s ease-in-out 0s, visibility 0s ease-in-out 0s;
    }

    // toolbar buttons hover color
    & button,
    & .ql-picker-label,
    & .ql-picker-item {
      &:hover,
      &:focus,
      &.ql-active,
      &.ql-selected {
        color: @noe-primary !important;
      }
    }
  }
}

.ql-editor {
  // same padding as all other AntDesign fields when editable, otherwise no padding at all
  padding: 4px 11px;

  // Except in readonly mode
  &.ql-editor-readonly {
    padding: 0;
    /* in readonly mode, cut the last element bottom margin that is useless */
    & > *:last-child {
      margin-bottom: 0;
    }
  }
}

/* border radius around the whole editor (see also borders in ant.css) */
.quill {
  border-radius: @borderRadius;

  /* placeholder */
  & > .ql-container > .ql-editor.ql-blank::before {
    color: #bfbfbf;
    left: 11px; /* padding for placeholder */
    font-style: unset;
  }
}
