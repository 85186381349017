/*** SPIN ***/

.spin {
  animation: spin 1.5s cubic-bezier(0.6, 0.15, 0.6, 0.85) infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*** BREATHE ***/

.breathe {
  animation: breathe 2.5s infinite ease-in-out;
}

@keyframes breathe {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
    transform: scale(0.97);
  }
  100% {
    opacity: 1;
  }
}

/*** PULSE ***/

.pulse {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  70% {
    transform: scale(1.05);
    box-shadow: 0 0 0 7px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

/*** FADE IN ***/

.fade-in {
  animation: fade-in 0.7s ease-out both;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in.from-bottom {
  animation: fade-in-from-bottom 0.7s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@keyframes fade-in-from-bottom {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/*** FADE OUT ***/

.fade-out {
  animation: fade-out 0.7s ease-in both;
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  99% {
    opacity: 0;
    height: initial;
  }
  100% {
    // Need to reduce height to prevent the element to overlay the rest of the page.
    // There is no way to toggle display = none with animations.
    height: 0;
  }
}

/*** BOUNCE IN ***/

.bounce-in {
  animation: bounceIn 0.55s ease-in-out both;
}

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.97);
  }
  50% {
    transform: scale(1.02);
  }
  80% {
    opacity: 0.9;
    transform: scale(0.99);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.bounce-in-strong {
  animation: bounceInStrong 0.55s ease-in-out both;
}

@keyframes bounceInStrong {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.07);
  }
  80% {
    opacity: 0.9;
    transform: scale(0.96);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
